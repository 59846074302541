import { CreateCashCarryOrderBody, TaxAddress } from "@/types/responses/buy"
import { StoreAddress } from "@/types/responses/explore"

export function getTaxAddress(
  market: string,
  customer?: CreateCashCarryOrderBody["customer"],
  storeAddress?: StoreAddress,
): TaxAddress | undefined {
  if (customer) {
    return {
      countryCode: market,
      stateCode: "stateCode" in customer ? market + customer.stateCode : market,
      city: customer.city,
      zipCode: customer.zipCode,
      addressLine1: customer.addressLine1,
      addressLine2:
        "addressLine2" in customer ? customer.addressLine2 : undefined,
    }
  } else if (
    storeAddress &&
    storeAddress.city &&
    storeAddress.zipCode &&
    storeAddress.street
  ) {
    return {
      countryCode: market,
      stateCode: storeAddress.stateProvinceCode,
      city: storeAddress.city,
      zipCode: storeAddress.zipCode,
      addressLine1: storeAddress.street,
    }
  } else {
    return undefined
  }
}
