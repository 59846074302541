import InputField from "@ingka/input-field"
import FormField from "@ingka/form-field"
import PhoneInput, {
  Country,
  getCountryCallingCode,
} from "react-phone-number-input/input"
import { FieldConfig, fieldLabels } from "@/config/captureProfileConfig"
import { useTranslation } from "react-i18next"

type Props = {
  showPanField: boolean
  market: string
  isValid: boolean
  value: string
  field: string
  shouldValidate: boolean
  setField: (field: string) => void
  handleBlur: (field: string) => void
  mandatory?: boolean
  type: FieldConfig["type"]
  inputMode: FieldConfig["inputMode"]
}

export const CaptureProfileField: React.FC<Props> = ({
  isValid,
  shouldValidate,
  field,
  setField,
  handleBlur,
  type,
  inputMode,
  showPanField,
  mandatory,
  value,
  market,
}) => {
  const { t } = useTranslation()
  const packageCountryCode = getCountryCallingCode(market as Country)

  if (field === "pan" && !showPanField) {
    return null
  }

  return (
    <>
      <FormField
        data-cy={field}
        shouldValidate={shouldValidate}
        valid={isValid}
        validation={{
          msg:
            value === ""
              ? t(fieldLabels[field]?.validationMsg.mandatory)
              : t(fieldLabels[field]?.validationMsg.invalid),
          id: "profile",
          type: "error",
        }}
      >
        {field === "mobile" ? (
          <div className="form-group">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-gray-700 pb-1"
            >
              {t(fieldLabels[field]?.label) + (mandatory ? "*" : "")}
            </label>
            <PhoneInput
              placeholder={`+${packageCountryCode}`}
              id="mobile"
              onChange={(phoneInputValue) => {
                setField(phoneInputValue ?? "")
              }}
              defaultCountry={market as Country}
              value={value}
              onBlur={() => handleBlur(field)}
              className={`phone-number-input w-full border p-3 placeholder-gray-200 rounded ${
                shouldValidate
                  ? isValid
                    ? "border-green-700 focus:border-2"
                    : "border-red-500 focus:border-red-600 focus:border-2"
                  : "border-gray-400 focus:border-gray-500 focus:border-2"
              }`}
            />
          </div>
        ) : (
          <InputField
            id={field}
            readOnly={field === "state"}
            label={t(fieldLabels[field]?.label) + (mandatory ? "*" : "")}
            type={type}
            value={value}
            onChange={(e) => {
              setField(e.target.value)
            }}
            onBlur={() => handleBlur(field)}
            inputMode={inputMode}
          />
        )}
      </FormField>
    </>
  )
}
