export interface ValidationRule {
  field: string
  regex?: {
    regexp: string
    flag: string
  }
  maxLength?: number
  minLength?: number
  mandatory?: boolean
  acceptedValues?: string[]
  acceptedValuesRegex?: {
    regexp: string
    flag: string
  }
}

export const validateField = (
  value: string,
  field: string,
  validationRules: ValidationRule[],
): boolean => {
  const rule = validationRules.find((rule) => rule.field === field)

  if (rule?.regex) {
    const regex = new RegExp(rule.regex.regexp, rule.regex.flag)

    // AddressLine2 and AddressLine3 are optional fields
    if (
      (rule.field === "addressLine2" || rule.field === "addressLine3") &&
      value === ""
    ) {
      return true
    }

    return regex.test(value)
  }
  return false
}

export const validatePanField = (panField: string) =>
  /^[a-zA-Z0-9]{10}$/.test(panField)

export const validatePrefectureField = (prefectureField: string) =>
  /^[a-zA-Z\u4e00-\u9faf\s-]+$/.test(prefectureField)
