import { ListProduct } from "@/types/product/listProduct"
import {
  AddItemsToOrderBodySchema,
  CreateCashCarryOrderBody,
  CreateOrderItem,
} from "@/types/responses/buy"

export function formatCcOrderListigItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["listigOrderItems"] {
  return products.map((product) => ({
    itemId: product.info.no,
    type: product.info.type,
    quantity: product.quantity ?? 0,
  }))
}

export function formatCcOrderItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["items"] | AddItemsToOrderBodySchema["items"] {
  const formattedItems = formatAndFlattenCcOrderItems(products)

  // filter items array to only contain unique items and update quantity for any duplicates
  return formattedItems?.reduce(
    (uniqueItems: CreateOrderItem[], formattedItem: CreateOrderItem) => {
      const duplicate = uniqueItems.find(
        (item) => item.itemId === formattedItem.itemId,
      )

      if (duplicate) {
        duplicate.quantity += formattedItem.quantity
      } else {
        uniqueItems.push(formattedItem)
      }

      return uniqueItems
    },
    [],
  )
}

function formatAndFlattenCcOrderItems(
  products: ListProduct[],
): CreateCashCarryOrderBody["items"] | AddItemsToOrderBodySchema["items"] {
  let formattedItems:
    | CreateCashCarryOrderBody["items"]
    | AddItemsToOrderBodySchema["items"] = []

  products.forEach((product) => {
    if (product.info.type === "ART") {
      formattedItems.push({
        itemId: product.info.no,
        type: product.info.type,
        quantity: product.quantity || 0,
        measurement: "PIECES",
      })
    } else {
      formattedItems = formattedItems.concat(
        formatCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    }
  })

  return formattedItems
}
