import { createOrder } from "@/api/createFullServeOrder"
import { useAuth } from "@/hooks/useAuth"
import { SessionState, useListig } from "@/hooks/useListig"
import { Locale, useLocale } from "@/hooks/useLocale"
import {
  dismissOrderCreatedNotification,
  setIsOrderCreationLoading,
  showOrderCreatedNotification,
  useCcOrderSnapshot,
} from "@/stores/ccOrder"
import { ApiError } from "@/types/apiError"
import { FullServeProduct } from "@/types/product/categorizedProduct"
import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { OrderConflictError, OrderForbiddenError } from "@/utils/errors"
import {
  formatCcOrderItems,
  formatCcOrderListigItems,
} from "@/utils/formatCcOrderItems"
import {
  FullServeRestriction,
  getFullServeRestriction,
} from "@/utils/fullServeRestrictions"
import { getListVpcCodes } from "@/utils/listig"
import { useEffect } from "react"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { useCo360 } from "./useCo360"
import { InstoreOrder, UpdateDeliveryArrangement } from "@/types/order"
import { ListigOrder } from "@/types/listig"
import { cancelOrder } from "@/api/cancelFullServeOrder"
import { editOrder } from "@/api/editFullServeOrder"
import { ListProduct } from "@/types/product/listProduct"
import { useStoreInformation } from "./useStoreInformation"
import { StoreAddress } from "@/types/responses/explore"
import { updateTimeSlot } from "@/api/updateTimeSlot"
import { getTaxAddress } from "@/utils/taxAddress"

export function useCcOrder(listigOrder?: ListigOrder, noTimeSlots = false) {
  const { oAuthToken } = useAuth()
  const locale = useLocale()
  const { orderCreatedNotificationVisible, isOrderCreationLoading } =
    useCcOrderSnapshot()
  const listig = useListig()
  const session = listig.session
  const fullServeProducts = categorizeProducts(listig.list).FULL_SERVE
  const queryClient = useQueryClient()
  const { storeInfo } = useStoreInformation(session.businessUnitCode ?? null)

  /** null if no restrictions, undefined if undecided */
  const orderRestriction: FullServeRestriction | "UNKNOWN_STORE" | null =
    session.businessUnitCode
      ? (getFullServeRestriction(
          fullServeProducts,
          locale.market,
          session.businessUnitCode,
          noTimeSlots,
        ) ?? null)
      : "UNKNOWN_STORE"

  const orderNo = listigOrder?.orderNo
  const isOrderCreated = !!orderNo
  const isCo360Enabled = useCo360()

  const vpcCodes = getListVpcCodes(fullServeProducts)
  const drawings: CreateCashCarryOrderBody["drawings"] = vpcCodes?.map(
    (vpcCode) => ({ drawingId: vpcCode }),
  )

  const mutation = useMutationWithErrorHandling(
    mutationFunction(
      orderRestriction,
      isOrderCreated,
      oAuthToken,
      session,
      fullServeProducts,
      locale,
      isCo360Enabled,
      drawings,
      storeInfo?.address,
    ),
    {
      onSuccess: (data) => {
        showOrderCreatedNotification()
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrderProducts", data.orderNo],
        })
        queryClient.invalidateQueries({
          queryKey: ["order status", data.orderNo],
        })
      },
      retryDelay: 2000,
      retry: (failureCount, error) =>
        // we are not allowed to retry on 500 range or if 409 (order already exist for list)
        !isHttpServerError(error) && !isHttpConflict(error) && failureCount < 3,
    },
    (error) => {
      if (isHttpConflict(error)) {
        return "info"
      } else if (error instanceof OrderForbiddenError) {
        return "warning"
      } else {
        return "error"
      }
    },
  )

  const cancelOrderMutation = useMutationWithErrorHandling(
    (currentOrder: InstoreOrder) => {
      if (
        !oAuthToken ||
        !currentOrder ||
        !session.source ||
        !session.businessUnitCode ||
        !session.listId
      ) {
        return Promise.reject(new Error("Missing fields"))
      }
      return cancelOrder({
        orderNo: currentOrder.orderNo,
        orderNoSource: currentOrder.orderNoSource,
        storeNo: session.businessUnitCode ?? "",
        language: locale.language,
        market: locale.market,
        ukid: session.source.type === "kiosk" ? session.source.ukid : undefined,
        kongToken: oAuthToken,
        listId: session.listId,
      })
    },
    {
      onSuccess: (_, currentOrder) => {
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrderProducts", currentOrder.orderNo],
        })
      },
      retryDelay: 2000,
      retry: (failureCount) => failureCount < 3,
    },
  )

  const editOrderMutation = useMutationWithErrorHandling(
    ({
      order: currentOrder,
      items,
      storeAddress,
      detailedTimeSlot,
    }: {
      order: InstoreOrder
      items: ListProduct[]
      storeAddress?: StoreAddress
      detailedTimeSlot?: UpdateDeliveryArrangement
    }) => {
      if (
        !oAuthToken ||
        !currentOrder ||
        !session.source ||
        !session.businessUnitCode ||
        !session.listId
      ) {
        return Promise.reject(new Error("Missing fields"))
      }
      return editOrder({
        orderNo: currentOrder.orderNo,
        orderNoSource: currentOrder.orderNoSource,
        storeNo: session.businessUnitCode ?? "",
        storeAddress: storeAddress,
        products: formatCcOrderItems(items ?? []),
        listigOrderItems: formatCcOrderListigItems(items),
        language: locale.language,
        market: locale.market,
        ukid: session.source.type === "kiosk" ? session.source.ukid : undefined,
        kongToken: oAuthToken,
        listId: session.listId,
        isCo360Enabled,
        detailedTimeSlot,
      })
    },
    {
      onSuccess: (_, { order: currentOrder }) => {
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrderProducts", currentOrder.orderNo],
        })
        queryClient.invalidateQueries({
          queryKey: ["order status", currentOrder.orderNo],
        })
      },
      retry: false,
    },
  )

  const editTimeSlotOnOrderMutation = useMutationWithErrorHandling(
    ({
      order: currentOrder,
      detailedTimeSlot,
      storeAddress,
    }: {
      order: InstoreOrder
      detailedTimeSlot: UpdateDeliveryArrangement
      storeAddress?: StoreAddress
    }) => {
      if (
        !oAuthToken ||
        !currentOrder ||
        !session.source ||
        !session.businessUnitCode ||
        !session.listId ||
        !detailedTimeSlot
      ) {
        return Promise.reject(new Error("Missing fields"))
      }
      return updateTimeSlot({
        market: locale.market,
        language: locale.language,
        storeNo: session.businessUnitCode,
        orderNo: currentOrder.orderNo,
        orderNoSource: currentOrder.orderNoSource,
        kongToken: oAuthToken,
        detailedTimeSlot,
        storeAddress: storeAddress,
      })
    },
    {
      onSuccess: (_, { order: currentOrder }) => {
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrderProducts", currentOrder.orderNo],
        })
        queryClient.invalidateQueries({
          queryKey: ["order status", currentOrder.orderNo],
        })
      },
      retry: false,
    },
  )

  useEffect(() => {
    setIsOrderCreationLoading(mutation.isPending || editOrderMutation.isPending)
  }, [mutation.isPending, editOrderMutation.isPending])

  const refetchListig = listig.refetch
  useEffect(() => {
    if (isHttpConflict(mutation.error)) {
      refetchListig()
    }
  }, [mutation.error, refetchListig])

  return {
    isOrderCreationLoading: mutation.isPending || isOrderCreationLoading,
    /** this value will only be available from
     *  the hook instance that created the order */
    orderCreationError: isHttpConflict(mutation.error)
      ? undefined
      : mutation.error,
    orderModificationError: isHttpConflict(editOrderMutation.error)
      ? undefined
      : editOrderMutation.error,
    orderNotification: {
      isShowing: orderCreatedNotificationVisible,
      dismiss: dismissOrderCreatedNotification,
    },
    orderTimeSlotModificationError: isHttpConflict(
      editTimeSlotOnOrderMutation.error,
    )
      ? undefined
      : editTimeSlotOnOrderMutation.error,

    createCcOrder: (
      options: {
        onSuccess?: (
          data: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse,
        ) => unknown
      },
      notification?: { contactMethodType: string; contactMethodData: string },
      customer?: CreateCashCarryOrderBody["customer"],
      timeSlotId?: string,
    ) => mutation.mutate({ notification, customer, timeSlotId }, options),
    cancelCcOrder: (order: InstoreOrder) =>
      cancelOrderMutation.mutate(order, undefined),
    editCcOrder: ({
      order,
      items,
      detailedTimeSlot,
    }: {
      order: InstoreOrder
      items: ListProduct[]
      detailedTimeSlot?: UpdateDeliveryArrangement
    }) =>
      editOrderMutation.mutate(
        {
          order,
          items,
          storeAddress: storeInfo?.address,
          detailedTimeSlot,
        },
        undefined,
      ),
    editTimeSlotOnOrder: ({
      order,
      detailedTimeSlot,
    }: {
      order: InstoreOrder
      detailedTimeSlot: UpdateDeliveryArrangement
    }) =>
      editTimeSlotOnOrderMutation.mutate(
        { order, detailedTimeSlot, storeAddress: storeInfo?.address },
        undefined,
      ),
    /** null if no restrictions, undefined if undecided */
    orderRestriction,
  }
}

/** Fetch function used in mutation */
function mutationFunction(
  orderRestriction: string | null,
  isOrderCreated: boolean,
  oAuthToken: string | undefined,
  session: SessionState,
  fullServeProducts: FullServeProduct[],
  locale: Locale,
  isCo360Enabled: boolean,
  drawings: CreateCashCarryOrderBody["drawings"],
  storeAddress?: StoreAddress,
) {
  return ({
    notification,
    customer,
    timeSlotId,
  }: {
    notification?: {
      contactMethodType: string
      contactMethodData: string
    }
    customer?: CreateCashCarryOrderBody["customer"]
    timeSlotId?: string
  }) =>
    orderRestriction || isOrderCreated
      ? Promise.reject(
          new OrderForbiddenError(
            orderRestriction
              ? "orderRestriction: " + orderRestriction
              : "orderCreated",
          ),
        )
      : !oAuthToken ||
          !session.businessUnitCode ||
          !session.listId ||
          !session.source
        ? Promise.reject(new Error("Missing fields"))
        : createOrder({
            storeNo: session.businessUnitCode,
            taxAddress: getTaxAddress(locale.market, customer, storeAddress),
            products: formatCcOrderItems(fullServeProducts),
            listigOrderItems: formatCcOrderListigItems(fullServeProducts),
            language: locale.language,
            market: locale.market,
            ukid:
              session.source.type === "kiosk" ? session.source.ukid : undefined,
            kongToken: oAuthToken,
            listId: session.listId,
            isCo360Enabled,
            drawings: drawings,
            notification,
            customer,
            timeSlotId,
          })
}

function isHttpConflict(error: Error | null) {
  return error && error instanceof OrderConflictError
}

function isHttpServerError(error: unknown) {
  return error && error instanceof ApiError && error.statusCode >= 500
}
