import Button from "@ingka/button"
import { ModalFooter } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import { SlideUpModal } from "../modals/SlideUpModal"

import { useEffect, useState } from "react"
import { PrivacyPolicyModal } from "../modals/PrivacyPolicyModal"
import { getValidationRulesByField } from "@ingka-group-digital/customer-fields-validation-rules/dist/validator/validationRulesExtractor"
import {
  CreateCashCarryOrderBody,
  CustomerSchema,
  IndiaCustomer,
  JapanCustomer,
  NorwayCustomer,
} from "@/types/responses/buy"
import {
  validateField,
  validatePanField,
  validatePrefectureField,
  ValidationRule,
} from "@/utils/validateField"
import { Country, isValidPhoneNumber } from "react-phone-number-input/input"
import { useListig } from "@/hooks/useListig"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { useGetCompleteAddress } from "@/hooks/useGetCompleteAddress"
import { CaptureProfileField } from "./CaptureProfileField"
import {
  FieldConfig,
  MarketConfigCaptureModal,
  PAN_FIELD_THRESHOLD,
} from "@/config/captureProfileConfig"

/**
 * Prompt to collect customer profile information for full serve order with tax point delivery
 */
export const CaptureProfileModal: React.FC<{
  show: boolean
  onContinue: (customer?: CreateCashCarryOrderBody["customer"]) => void
  onCancel: () => void
  market: string
  language: string
  marketConfig: MarketConfigCaptureModal
}> = ({ show, onContinue, onCancel, market, marketConfig, language }) => {
  const { fields } = marketConfig
  const { t } = useTranslation()

  const [showPolicy, setShowPolicy] = useState<boolean>(false)

  // Initialize fieldValues, fieldValidations, and shouldValidateFields based on the fields for the selected market
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>(
    initializeFieldValues(fields),
  )

  const [fieldValidations, setFieldValidations] = useState<{
    [key: string]: boolean
  }>(initializeFieldValidations(fields))

  const [shouldValidateFields, setShouldValidateFields] = useState<{
    [key: string]: boolean
  }>(initializeShouldValidateFields(fields))

  const listig = useListig()
  const fullServeProducts = categorizeProducts(listig.list).FULL_SERVE
  const totalPrice = calculatePriceTotals(fullServeProducts)

  // Pan field is required when total price is greater than or equal to 200000
  const showPanField =
    market === "IN" && totalPrice.regular.inclTax >= PAN_FIELD_THRESHOLD

  const { data: completeAddress } = useGetCompleteAddress(
    market,
    language,
    fieldValues.zipCode ?? "",
  )

  useEffect(() => {
    if (market === "IN") {
      setFieldValues((prev) => ({
        ...prev,
        city: "",
        state: "",
      }))
    }
  }, [fieldValues.zipCode, market])

  const validationRules = getValidationRulesByField(
    [
      "FIRST_NAME",
      "LAST_NAME",
      "FIRST_NAME_PHONETIC",
      "LAST_NAME_PHONETIC",
      "MOBILE",
      "ADDRESS_LINE1",
      "ADDRESS_LINE2",
      "ADDRESS_LINE3",
      "POST_CODE",
      "CITY",
      "EMAIL",
    ],
    market,
  ) as ValidationRule[]

  const validateAllFields = () => {
    const newFieldValidations = { ...fieldValidations }

    Object.keys(fieldValidations).forEach((field) => {
      if (field === "state") {
        newFieldValidations[field] = true
      } else if (field === "prefecture") {
        newFieldValidations[field] = validatePrefectureField(
          fieldValues[field] || "",
        )
      } else if (field === "mobile") {
        newFieldValidations[field] = isValidPhoneNumber(
          fieldValues[field] || "",
          market as Country,
        )
      } else if (field === "pan") {
        newFieldValidations[field] =
          !showPanField || validatePanField(fieldValues[field] || "")
      } else {
        newFieldValidations[field] = validateField(
          fieldValues[field] || "",
          field,
          validationRules,
        )
      }
    })

    setFieldValidations(newFieldValidations)

    // Check if all fields are valid
    return Object.values(newFieldValidations).every((isValid) => isValid)
  }

  const handleProfileSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    let customer: CustomerSchema | undefined

    if (market === "IN") {
      customer = {
        firstName: fieldValues.firstName,
        lastName: fieldValues.lastName,
        mobileNumber: fieldValues.mobile,
        emailAddress: fieldValues.email,
        addressLine1: fieldValues.addressLine1,
        addressLine2: fieldValues.addressLine2,
        addressLine3: fieldValues.addressLine3,
        zipCode: fieldValues.zipCode,
        city: fieldValues.city,
        countryCode: market,
        stateCode: completeAddress?.stateCode,
        pan: showPanField ? fieldValues.pan : undefined,
      } as IndiaCustomer
    } else if (market === "NO") {
      customer = {
        firstName: fieldValues.firstName,
        lastName: fieldValues.lastName,
        mobileNumber: fieldValues.mobile,
        emailAddress: fieldValues.email,
        addressLine1: fieldValues.addressLine1,
        zipCode: fieldValues.zipCode,
        city: fieldValues.city,
        countryCode: market,
      } as NorwayCustomer
    } else if (market === "JP") {
      customer = {
        firstName: fieldValues.firstName,
        lastName: fieldValues.lastName,
        mobileNumber: fieldValues.mobile,
        emailAddress: fieldValues.email,
        addressLine1: fieldValues.addressLine1,
        addressLine2: fieldValues.addressLine2,
        phoneticFirstName: fieldValues.firstName,
        phoneticLastName: fieldValues.lastName,
        prefecture: fieldValues.prefecture,
        city: fieldValues.city,
        zipCode: fieldValues.zipCode,
        countryCode: market,
      } as JapanCustomer
    }

    if (validateAllFields()) {
      onContinue(customer)
    } else {
      setShouldValidateFields({
        firstName: true,
        lastName: true,
        mobile: true,
        email: true,
        addressLine1: true,
        addressLine2: true,
        addressLine3: true,
        zipCode: true,
        city: true,
        state: true,
        pan: true,
        prefecture: true,
        firstNamePhonetic: true,
        lastNamePhonetic: true,
      })
    }
  }

  const handleFieldBlur = (field: string) => {
    setShouldValidateFields((prev) => ({ ...prev, [field]: true }))
    if (field === "mobile") {
      setFieldValidations((prev) => ({
        ...prev,
        [field]: isValidPhoneNumber(fieldValues[field] || ""),
      }))
    } else if (field === "prefecture") {
      setFieldValidations((prev) => ({
        ...prev,
        [field]: validatePrefectureField(fieldValues[field] || ""),
      }))
    } else if (field === "state") {
      return
      //This can be removed when Pan field is implemented in the npm package, as of now there is no validation for PAN field
    } else if (field === "pan") {
      setFieldValidations((prev) => ({
        ...prev,
        [field]: validatePanField(fieldValues[field] || ""),
      }))
    } else if (field === "zipCode" && market === "IN") {
      // As of now, autopopulation works only for India
      setFieldValidations((prev) => ({
        ...prev,
        [field]:
          validateField(fieldValues["zipCode"] || "", field, validationRules) &&
          !!completeAddress?.state,
      }))

      if (completeAddress) {
        setFieldValues((prev) => ({
          ...prev,
          city: completeAddress.city,
          state: completeAddress.state,
        }))
      }
    } else {
      setFieldValidations((prev) => ({
        ...prev,
        [field]: validateField(
          fieldValues[field] || "",
          field,
          validationRules,
        ),
      }))
    }
  }

  return (
    <SlideUpModal onClose={onCancel} showing={show}>
      <h2 className="text-2xl mb-12" data-cy="modal-header">
        {t("mobile.ready-to-order")}

        <p className="text-sm font-thin pt-3">
          {t("mobile.profile-capture-info")}
        </p>
      </h2>

      <>
        <form onSubmit={handleProfileSubmit}>
          <div className="space-y-4">
            {fields.map(
              ({ fieldName, mandatory, inputMode, type }: FieldConfig) => (
                <CaptureProfileField
                  showPanField={showPanField}
                  market={market}
                  value={fieldValues[fieldName] || ""}
                  key={fieldName}
                  handleBlur={handleFieldBlur}
                  field={fieldName}
                  setField={(fieldValue) => {
                    setFieldValues((prev) => ({
                      ...prev,
                      [fieldName]: fieldValue,
                    }))
                    setShouldValidateFields((prev) => ({
                      ...prev,
                      [fieldName]: false,
                    }))
                  }}
                  isValid={fieldValidations[fieldName] || false}
                  shouldValidate={shouldValidateFields[fieldName] || false}
                  type={type}
                  inputMode={inputMode}
                  mandatory={mandatory}
                />
              ),
            )}
          </div>
        </form>

        <div className="pt-7">
          <p className="text-xs text-gray-400 mt-0 mb-10">
            {t("mobile.show-privacy-policy")}&nbsp;
            <span onClick={() => setShowPolicy(true)} className="underline">
              {t("common.privacy-policy-anchor-text")}
            </span>
          </p>
          <PrivacyPolicyModal
            showPolicy={showPolicy}
            onClose={() => setShowPolicy(false)}
            market={market}
            language={language}
          />
        </div>
      </>

      <ModalFooter className="sticky bottom-0 bg-white z-10 px-0 py-4">
        <Button text={t("mobile.not-yet")} onClick={onCancel} />
        <Button
          data-cy="confirm-order-button"
          type="emphasised"
          text={t("mobile.place-order")}
          htmlType="submit"
          onClick={(e: React.MouseEvent) => {
            handleProfileSubmit(e)
          }}
        />
      </ModalFooter>
    </SlideUpModal>
  )
}

const initializeFieldValues = (fields: FieldConfig[]) => {
  const initialValues: { [key: string]: string } = {}
  fields.forEach(({ fieldName }) => {
    initialValues[fieldName] = ""
  })
  return initialValues
}

const initializeFieldValidations = (fields: FieldConfig[]) => {
  const initialValidations: { [key: string]: boolean } = {}
  fields.forEach(({ fieldName }) => {
    initialValidations[fieldName] = true
  })
  return initialValidations
}

const initializeShouldValidateFields = (fields: FieldConfig[]) => {
  const initialShouldValidate: { [key: string]: boolean } = {}
  fields.forEach(({ fieldName }) => {
    initialShouldValidate[fieldName] = false
  })
  return initialShouldValidate
}
