import { SlideUpModal } from "../modals/SlideUpModal"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"

export const TimeSlotsErrorModal: React.FC<{
  show: boolean
  onClose: () => void
}> = ({ show, onClose }) => {
  const { t } = useTranslation()

  return (
    <SlideUpModal
      onClose={() => {
        onClose()
      }}
      showing={show}
    >
      <div className="error-message text-center" data-cy="time-slots-error">
        <h1 className="pb-4 text-lg">
          {t("Interstitial-FullServe-warning-title")}
        </h1>
        <p className="font-normal text-sm">
          {t("mobile.tsm-update-error-message")}
        </p>
        <Button
          data-cy="time-slots-continue"
          className="sticky bottom-0 w-full bg-white pt-6"
          type="primary"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            onClose()
          }}
        >
          {t("common.got-it")}
        </Button>
      </div>
    </SlideUpModal>
  )
}
