import { usePickUpNumber } from "@/hooks/usePickUpNumber"
import PickupNumber from "./PickupNumber"
import FullServeStatusText, { FullServeStatus } from "./FullServeStatusText"
import SSRIcon from "@ingka/ssr-icon"
import { getFullServeStatus } from "@/utils/getFullServeStatus"
import { Trans, useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { OrderView } from "./OrderView"
import { motion } from "framer-motion"
import { BaseImage } from "@/types/responses/find"
import Loading from "@/components/common/Loading"
import { useLoadingSteps } from "@/hooks/useLoadingSteps"
import checkIcon from "@ingka/ssr-icon/paths/checkmark"
import { sendOrderDetailsCloseEvent } from "@/analytics/events/sendOrderDetailsCloseEvent"
import { sendOrderDetailsOpenEvent } from "@/analytics/events/sendOrderDetailsOpenEvent"
import { FullScreenModal } from "../modals/FullscreenModal"
import { ListigOrder } from "@/types/listig"
import { useOrderInformation } from "@/hooks/useOrderInformation"
import { InstoreOrder, TimeSlot } from "@/types/order"
import { OrderInformation } from "./OrderInformation"
import { useWaitingTime } from "@/hooks/useWaitingTime"
import Button from "@ingka/button"
import barcode from "@ingka/ssr-icon/paths/scan-barcode"
import { useProducts } from "@/hooks/useProducts"
import { useListig } from "@/hooks/useListig"
import { categorize } from "@/utils/categorizeProducts"
import { QuantityBadge } from "./QuantityBadge"
import ProgressBar from "./ProgressBar"
import {
  useTimeSlotManagement,
  useTimeSlotManagementUpdate,
} from "@/hooks/useTimeSlotManagement"
import { TimeSlotsModal } from "./TimeSlotsModal"
import { sendChangeTimeSlotEvent } from "@/analytics/events/sendChangeTimeSlotEvent"
import { getTimeFromDateTime } from "@/utils/formatDateTime"
import { canChangeTimeSlot } from "@/utils/canChangeTimeSlot"
import { useCcOrder } from "@/hooks/useCcOrder"
import { useGetDetailedTimeSlots } from "@/hooks/useGetDetailedTimeSlots"
import { buildUpdateTimeSlotPayload } from "@/utils/timeSlots"
import { TimeSlotsErrorModal } from "./TimeSlotsErrorModal"
import Barcode from "react-barcode"
import useWindowSize from "@/hooks/useWindowSize"
import { ORDER_FAST_TRACK_ENABLED } from "@/config/constants"

const MAX_THUMBNAILS = 4

const BARCODE_LENGTH = 16

const Thumbnail: React.FC<{
  image?: BaseImage
  text?: string
  quantity?: number
}> = ({ image, text, quantity }) => {
  const diameter = 72
  const imgSize = Math.round(Math.sqrt(2) * (diameter / 2))

  return (
    <div data-cy="order-card-thumbnail" className="relative mr-3 last:mr-0">
      <div
        className={`flex justify-center items-center w-[${diameter}px] h-[${diameter}px] rounded-full overflow-hidden border-[1px] border-solid border-gray-200`}
      >
        {image && (
          <img
            style={{
              width: imgSize + "px",
              height: imgSize + "px",
            }}
            src={image.href}
            alt={image.alt}
          />
        )}
        {text && <div className="text-black font-normal text-sm">{text}</div>}
      </div>

      {quantity && (
        <div className="absolute bottom-[-3px] right-[-3px]">
          <QuantityBadge quantity={quantity} />
        </div>
      )}
    </div>
  )
}

interface OrderCardProps {
  listigOrder: ListigOrder
  isOrderCreationLoading: boolean
  onCancelOrder: (order: InstoreOrder) => void
}

export const OrderCard: React.FC<OrderCardProps> = ({
  listigOrder,
  isOrderCreationLoading,
  onCancelOrder,
}) => {
  const { editTimeSlotOnOrder, orderTimeSlotModificationError } = useCcOrder()
  const { order, isOrderLoading } = useOrderInformation(
    listigOrder.orderNo,
    listigOrder.orderNoSource,
  )
  const { session } = useListig()
  const { products: parentProducts } = useProducts(
    session.businessUnitCode,
    listigOrder.items,
  )
  const { t } = useTranslation()
  const { pickupNumber } = usePickUpNumber(order?.orderNo)
  const isWaitingTimeEnabled = useWaitingTime()
  const isTimeSlotManagementEnabled = useTimeSlotManagement()
  const isTimeSlotManagementUpdateEnabled = useTimeSlotManagementUpdate()

  const { data: detailedTimeSlots } = useGetDetailedTimeSlots(
    session.businessUnitCode,
    order?.items,
  )
  const timeSlots = detailedTimeSlots?.timeWindows

  const fullServeStatus = getFullServeStatus(
    !!order?.isPaid,
    order?.pickingStatus,
  )
  const [showOrderView, setShowOrderView] = useState<boolean>(false)
  const [showChangeTimeSlot, setShowChangeTimeSlot] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [showOrderCreated, setShowOrderCreated] = useState<boolean>(false)
  const [showTimeSlotModal, setShowTimeSlotModal] = useState<boolean>(false)
  const [showTimeSlotErrorModal, setShowTimeSlotErrorModal] =
    useState<boolean>(false)

  const loadingSteps = [
    {
      completionStep: !loading,
      loadingText: t("mobile.placing-order"),
    },
    {
      completionStep: !!pickupNumber && isOrderLoading === false,
      loadingText: t("common.loading"),
    },
  ]
  const { steps, completed } = useLoadingSteps(loadingSteps, true)

  const onClose = () => {
    sendOrderDetailsCloseEvent(order?.orderNo)
    setShowOrderView(false)
  }

  useEffect(() => {
    if (orderTimeSlotModificationError) {
      setShowTimeSlotErrorModal(true)
    }
  }, [orderTimeSlotModificationError])

  // Show loading view for the first 2 seconds after an order has been created, then show order created confirmation for 1 second
  useEffect(() => {
    if (isOrderCreationLoading) {
      setTimeout(() => {
        setLoading(false)
        setShowOrderCreated(true)

        setTimeout(() => setShowOrderCreated(false), 1000)
      }, 2000)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (order?.timeSlot) {
      setShowChangeTimeSlot(
        canChangeTimeSlot(
          isTimeSlotManagementUpdateEnabled,
          timeSlots,
          order.timeSlot,
        ),
      )
    }
  }, [timeSlots, order, isTimeSlotManagementUpdateEnabled])

  const categorizedProducts = categorize(parentProducts ?? [])

  // Latest added products should be displayed first
  const reversedProducts = [
    ...categorizedProducts.FULL_SERVE,
    ...categorizedProducts.ONLINE,
  ].reverse()

  const thumbnailsToShow =
    reversedProducts.length > MAX_THUMBNAILS
      ? MAX_THUMBNAILS - 1
      : MAX_THUMBNAILS
  const thumbnailsToHide = reversedProducts.length - thumbnailsToShow

  const productsToShow = reversedProducts.slice(0, thumbnailsToShow)

  const { width: windowWidth } = useWindowSize()
  const scaleFactor = (Math.min(windowWidth, 415) * 0.8) / 290

  return (
    <div>
      {order && (
        <FullScreenModal showing={showOrderView} onClose={onClose}>
          <OrderView
            order={order}
            parentProducts={reversedProducts}
            onClose={onClose}
            onCancelOrder={() => {
              onCancelOrder(order)
              setShowOrderView(false)
            }}
          />
        </FullScreenModal>
      )}

      <div
        data-cy="order-card"
        className="rounded bg-white"
        onClick={
          completed
            ? () => {
                sendOrderDetailsOpenEvent("card", order?.orderNo)
                setShowOrderView(true)
              }
            : undefined
        }
      >
        <div className="flex flex-col items-start p-6 space-y-4">
          <div className="flex flex-wrap">
            {productsToShow.map((product) => (
              <Thumbnail
                key={product.info.no}
                image={product.media.mainImage}
                quantity={product.quantity}
              />
            ))}
            {thumbnailsToHide > 0 && (
              <Thumbnail text={`+${thumbnailsToHide}`} />
            )}
          </div>

          <div className="flex flex-col space-y-2 w-full">
            {completed ? (
              showOrderCreated ? (
                <motion.div
                  animate={{ opacity: "100%" }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="flex flex-col items-center mb-10">
                    <SSRIcon
                      paths={checkIcon}
                      className="h-14 w-14 text-blue-brand"
                    />
                    <p className="text-base">{t("mobile.order-created")}</p>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  className="flex flex-col space-y-4"
                  animate={{ opacity: "100%" }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <div>
                    <p className="text-sm font-normal mb-1 text-gray-250">
                      {t("mobile.your-order")}
                    </p>
                    <PickupNumber
                      pickupNumber={pickupNumber}
                      pickingStatus={order?.pickingStatus}
                      location="left"
                      showOnlyNumber
                    />
                  </div>

                  <FullServeStatusText status={fullServeStatus} />

                  {(isWaitingTimeEnabled || isTimeSlotManagementEnabled) &&
                    order?.pickingStatus !== "COMPLETED" && (
                      <ProgressBar status={fullServeStatus} />
                    )}

                  {/* TODO: It should be possible for customer to change the time all through the journey, except for in the final minutes when co-worker has marked that they are in the final picking stage. */}
                  {fullServeStatus !== "READY_FOR_PICKUP" &&
                  fullServeStatus !== "COLLECTED" &&
                  isTimeSlotManagementEnabled &&
                  order?.timeSlot ? (
                    <TimeSlotOrderCard
                      status={fullServeStatus}
                      timeSlot={order.timeSlot}
                      setShowTimeSlotModal={setShowTimeSlotModal}
                      canChangeTimeSlot={showChangeTimeSlot}
                    />
                  ) : (
                    !ORDER_FAST_TRACK_ENABLED && (
                      <OrderInformation
                        status={fullServeStatus}
                        orderPaidDate={order?.orderPaidDate}
                        estimatedReadyTime={order?.estimatedReadyTime}
                        isWaitingTimeEnabled={
                          isWaitingTimeEnabled || isTimeSlotManagementEnabled
                        }
                        orderCard={true}
                      />
                    )
                  )}
                </motion.div>
              )
            ) : (
              <Loading type="step" steps={steps} className="mb-10" />
            )}
          </div>
        </div>

        {order && ORDER_FAST_TRACK_ENABLED && (
          <div className="flex flex-col">
            <div
              data-cy="order-view-barcode"
              className="flex flex-col self-center"
              style={{ transform: `scaleX(${scaleFactor})` }}
            >
              <Barcode
                value={order.orderNo.padStart(BARCODE_LENGTH, "0")}
                width={2}
                height={48}
                // "ITF" is currently not supported by the wrapping library
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                format={"ITF" as any}
                displayValue={false}
                margin={0}
                data-cy="barcode"
              />
            </div>
            <p className="text-sm p-6">
              <Trans
                i18nKey={`${t("mobile.scan-to-pay")} ${t("mobile.start-preparing-order-bold")}`}
              />
            </p>
          </div>
        )}

        {completed && !showOrderCreated && (
          <motion.div
            className="flex w-full justify-center py-5"
            animate={{ opacity: "100%" }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          >
            <Button
              data-cy="order-card-button"
              className="w-full px-5"
              ssrIcon={!order?.isPaid ? barcode : undefined}
              type={order?.isPaid ? "secondary" : "emphasised"}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                sendOrderDetailsOpenEvent("button", order?.orderNo)
                setShowOrderView(true)
              }}
            >
              {order?.isPaid
                ? t("mobile.view-details")
                : t("mobile.pay-and-collect")}
            </Button>
          </motion.div>
        )}
      </div>

      {orderTimeSlotModificationError && (
        <TimeSlotsErrorModal
          show={showTimeSlotErrorModal}
          onClose={() => setShowTimeSlotErrorModal(false)}
        />
      )}

      {timeSlots && (
        <TimeSlotsModal
          timeSlots={timeSlots}
          chosenTimeSlotId={
            timeSlots.find((ts) => ts.id === order?.timeSlot?.id)?.id ??
            timeSlots[0]?.id
          }
          show={showTimeSlotModal}
          onClose={() => setShowTimeSlotModal(false)}
          onContinue={(timeSlotId: string) => {
            const selectedTimeSlot = timeSlots.find(
              (ts) => ts.id === timeSlotId,
            )

            // This cannot actually happen, but it's here to satisfy TypeScript
            if (order && selectedTimeSlot) {
              editTimeSlotOnOrder({
                order,
                detailedTimeSlot: buildUpdateTimeSlotPayload(
                  detailedTimeSlots,
                  selectedTimeSlot,
                ),
              })
            }
            setShowTimeSlotModal(false)
          }}
        />
      )}
    </div>
  )
}

interface TimeSlotOrderCardProps {
  status?: FullServeStatus
  timeSlot: TimeSlot
  setShowTimeSlotModal: React.Dispatch<React.SetStateAction<boolean>>
  canChangeTimeSlot: boolean
}

const TimeSlotOrderCard: React.FC<TimeSlotOrderCardProps> = ({
  status,
  timeSlot,
  setShowTimeSlotModal,
  canChangeTimeSlot,
}) => {
  const { t } = useTranslation()

  const collectionTime =
    getTimeFromDateTime(timeSlot.fromDateTime) +
    " - " +
    getTimeFromDateTime(timeSlot.toDateTime)

  return (
    <div className="flex flex-row justify-between">
      <div className="flex-grow">
        {status === "WAITING_FOR_PAYMENT" ? (
          <div className="text-sm" data-cy="pay-before-and-collect">
            <Trans
              i18nKey={t("mobile.pay-before-and-collect", {
                payTime: getTimeFromDateTime(timeSlot.paymentCutOffDateTime),
                collectTime: collectionTime,
              })}
            />
          </div>
        ) : (
          <div>
            <div className="text-sm">{t("mobile.collection-time")}:</div>
            <div className="text-xl pt-1">{collectionTime}</div>
          </div>
        )}
      </div>

      {canChangeTimeSlot && (
        <div className="flex-shrink-0">
          <Button
            type="tertiary"
            className="bg-neutral-100 ml-4"
            size="small"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              sendChangeTimeSlotEvent("order_page")
              setShowTimeSlotModal(true)
            }}
          >
            {t("common.change")}
          </Button>
        </div>
      )}
    </div>
  )
}
