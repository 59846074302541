export type MarketCaptureModal = "JP" | "IN" | "NO"

export interface FieldConfig {
  fieldName: string
  mandatory: boolean
  inputMode:
    | "text"
    | "email"
    | "search"
    | "none"
    | "tel"
    | "url"
    | "numeric"
    | "decimal"
    | undefined
  type: "number" | "text" | "email" | "password" | "hidden"
}

export interface MarketConfigCaptureModal {
  fields: FieldConfig[]
}

export const PAN_FIELD_THRESHOLD = 200000

//Use field name from NPM package when creating a new field if you want validation to work
export const marketConfigCaptureModal: Record<
  MarketCaptureModal,
  MarketConfigCaptureModal
> = {
  IN: {
    fields: [
      {
        fieldName: "firstName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "lastName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "mobile",
        mandatory: true,
        inputMode: "tel",
        type: "text",
      },
      {
        fieldName: "email",
        mandatory: true,
        inputMode: "email",
        type: "email",
      },
      {
        fieldName: "addressLine1",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "addressLine2",
        mandatory: false,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "addressLine3",
        mandatory: false,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "zipCode",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      { fieldName: "city", mandatory: true, inputMode: "text", type: "text" },
      {
        fieldName: "state",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      { fieldName: "pan", mandatory: true, inputMode: "text", type: "text" },
    ],
  },
  NO: {
    fields: [
      {
        fieldName: "firstName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "lastName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "addressLine1",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "zipCode",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      { fieldName: "city", mandatory: true, inputMode: "text", type: "text" },
      {
        fieldName: "email",
        mandatory: true,
        inputMode: "email",
        type: "email",
      },
      {
        fieldName: "mobile",
        mandatory: true,
        inputMode: "tel",
        type: "text",
      },
    ],
  },
  JP: {
    fields: [
      {
        fieldName: "lastName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "firstName",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "lastNamePhonetic",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "firstNamePhonetic",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "email",
        mandatory: true,
        inputMode: "email",
        type: "email",
      },
      {
        fieldName: "mobile",
        mandatory: true,
        inputMode: "tel",
        type: "text",
      },
      {
        fieldName: "zipCode",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "prefecture",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      { fieldName: "city", mandatory: true, inputMode: "text", type: "text" },
      {
        fieldName: "addressLine1",
        mandatory: true,
        inputMode: "text",
        type: "text",
      },
      {
        fieldName: "addressLine2",
        mandatory: false,
        inputMode: "text",
        type: "text",
      },
    ],
  },
}

type FieldLabels = {
  [key: string]: {
    label: string
    validationMsg: {
      mandatory: string
      invalid: string
    }
  }
}

export const fieldLabels: FieldLabels = {
  firstName: {
    label: "fields.fields-name.first-name",
    validationMsg: {
      mandatory: "fields.mandatory.first-name-is-mandatory",
      invalid: "fields.invalid.first-name",
    },
  },
  lastName: {
    label: "fields.fields-name.last-name",
    validationMsg: {
      mandatory: "fields.mandatory.last-name-is-mandatory",
      invalid: "fields.invalid.last-name",
    },
  },
  mobile: {
    label: "common.mobile-number",
    validationMsg: {
      mandatory: "fields.mandatory.mobile-phone-number-is-mandatory",
      invalid: "mobile.invalid-phone-number",
    },
  },
  email: {
    label: "common.email-address",
    validationMsg: {
      mandatory: "fields.mandatory.email-address-is-mandatory",
      invalid: "fields.invalid.email-address",
    },
  },
  addressLine1: {
    label: "fields.fields-name.address-line-1",
    validationMsg: {
      mandatory: "fields.mandatory.address-line-1-is-mandatory",
      invalid: "fields.invalid.address",
    },
  },
  addressLine2: {
    label: "fields.fields-name.address-line-2",
    validationMsg: {
      mandatory: "fields.mandatory.address-line-2-is-mandatory",
      invalid: "fields.invalid.address",
    },
  },
  addressLine3: {
    label: "fields.fields-name.address-line-3",
    validationMsg: {
      mandatory: "fields.mandatory.address-line-3-is-mandatory",
      invalid: "fields.invalid.address",
    },
  },
  zipCode: {
    label: "common.postal-code",
    validationMsg: {
      mandatory: "fields.mandatory.postal-code-is-mandatory",
      invalid: "fields.invalid.postal-code",
    },
  },
  city: {
    label: "mobile.city",
    validationMsg: {
      mandatory: "mobile.city-is-mandatory",
      invalid: "fields.invalid.city",
    },
  },
  state: {
    label: "fields.fields-name.state",
    validationMsg: {
      mandatory: "fields.mandatory.state-is-mandatory",
      invalid: "fields.invalid.state",
    },
  },
  pan: {
    label: "fields.fields-name.pan",
    validationMsg: {
      mandatory: "fields.mandatory.pan-is-mandatory",
      invalid: "fields.invalid.pan",
    },
  },
  firstNamePhonetic: {
    label: "fields.fields-name.phonetic-first-name",
    validationMsg: {
      mandatory: "fields.mandatory.phonetic-first-name-is-mandatory",
      invalid: "fields.invalid.phonetic-first-name",
    },
  },
  lastNamePhonetic: {
    label: "fields.fields-name.phonetic-last-name",
    validationMsg: {
      mandatory: "fields.mandatory.phonetic-last-name-is-mandatory",
      invalid: "fields.invalid.phonetic-last-name",
    },
  },
  prefecture: {
    label: "fields.fields-name.prefecture",
    validationMsg: {
      mandatory: "fields.mandatory.prefecture-is-mandatory",
      invalid: "fields.invalid.prefecture",
    },
  },
}
